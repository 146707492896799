import { useUserStore } from "~/store/userconfig";
import { useServiciosStore } from "~/store/servicios";

export default defineNuxtRouteMiddleware((to) => {
  // const users = useUserStore();
  // const servs = useServiciosStore();
  // if (!to.params.servicio) {
  //   const servicioUserId = users.servicio;
  //   if (servicioUserId) {
  //     const servicio = servs.servicios.get(servicioUserId);
  //     return navigateTo(`/${servicio?.slug}`, {
  //       replace: true,
  //     });
  //   } else {
  //     return navigateTo("/biotren", {
  //       replace: true,
  //     });
  //   }
  // } else {
  //   servs.setServicio({ slug: String(to.params.servicio) });
  // }
});