
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "umami": {
    "id": "fcc6d0c2-25d0-44e9-9588-908d2e020825",
    "host": "https://momo.miloplacencia.cl",
    "version": 2,
    "domains": "",
    "debug": false,
    "autoTrack": true,
    "useDirective": true,
    "customEndpoint": "/",
    "ignoreLocalhost": false
  },
  "nuxt": {
    "buildId": "c07105a9-1c3c-4816-8df0-2f89048cab0f"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/src/app/app.config.ts"
import cfg1 from "/usr/src/app/node_modules/.pnpm/nuxt-umami@2.6.2/node_modules/nuxt-umami/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
