import revive_payload_client_f0gb4EoyQC from "/usr/src/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.14.10_eslint@9.6.0_rollup@2.79.1_sass@1.77.6_stylelint@16.6.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_31L3U0l2AO from "/usr/src/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.14.10_eslint@9.6.0_rollup@2.79.1_sass@1.77.6_stylelint@16.6.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_sUZ7dg8lYQ from "/usr/src/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.14.10_eslint@9.6.0_rollup@2.79.1_sass@1.77.6_stylelint@16.6.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_oSZrtHWsWt from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@2.2.13_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.31_axios@1.7.2_nuxt@3.11._rlfrtokcybzoyblt5fybevnzda/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_U2YdxIjm5n from "/usr/src/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.14.10_eslint@9.6.0_rollup@2.79.1_sass@1.77.6_stylelint@16.6.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_VcPcj4Hufg from "/usr/src/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.14.10_eslint@9.6.0_rollup@2.79.1_sass@1.77.6_stylelint@16.6.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_ibbK30Zlax from "/usr/src/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@2.79.1_typescript@5.5.3_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_Dgj2Y5Ah6v from "/usr/src/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.14.10_eslint@9.6.0_rollup@2.79.1_sass@1.77.6_stylelint@16.6.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_ZLrx0h47ER from "/usr/src/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.4_rollup@2.79.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import defaults_1GLZex1uCK from "/usr/src/app/node_modules/.pnpm/@nuxtseo+module@2.0.0-beta.55_@lezer+common@1.2.1_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4_f5rmohrusmlxrgwmhzmt5556xm/node_modules/@nuxtseo/module/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_vjFiU7Lafb from "/usr/src/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.31_axios@1.7.2_nu_67qurjoyqni6vbbrwghozow7hm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_lX3MdrzEA0 from "/usr/src/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.31_axios@1.7.2_nu_67qurjoyqni6vbbrwghozow7hm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import pwa_icons_MdCFzHqBmI from "/usr/src/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_@vite-pwa+assets-generator@0.2.4_rollup@2.79.1_vite@5.3.3_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_HQKlZKdvO1 from "/usr/src/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_@vite-pwa+assets-generator@0.2.4_rollup@2.79.1_vite@5.3.3_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_yaRfD3nClN from "/usr/src/app/node_modules/.pnpm/nuxt-gtag@2.0.6_rollup@2.79.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_client_FUzMblK75w from "/usr/src/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@2.79.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_OwfuJ5kbYW from "/usr/src/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.14.10_eslint@9.6.0_rollup@2.79.1_sass@1.77.6_stylelint@16.6.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import umami_UbpOuIUCD6 from "/usr/src/app/node_modules/.pnpm/nuxt-umami@2.6.2/node_modules/nuxt-umami/plugins/umami.ts";
import _2_pouchdb_client_rNyFaRK3ZK from "/usr/src/app/plugins/2.pouchdb.client.ts";
import axios_QMFgzss1s4 from "/usr/src/app/plugins/axios.ts";
import sentry_client_shVUlIjFLk from "/usr/src/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_f0gb4EoyQC,
  unhead_31L3U0l2AO,
  router_sUZ7dg8lYQ,
  _0_siteConfig_oSZrtHWsWt,
  payload_client_U2YdxIjm5n,
  check_outdated_build_client_VcPcj4Hufg,
  plugin_vue3_ibbK30Zlax,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Dgj2Y5Ah6v,
  nuxt_plugin_ZLrx0h47ER,
  defaults_1GLZex1uCK,
  siteConfig_vjFiU7Lafb,
  inferSeoMetaPlugin_lX3MdrzEA0,
  pwa_icons_MdCFzHqBmI,
  pwa_client_HQKlZKdvO1,
  plugin_client_yaRfD3nClN,
  plugin_client_FUzMblK75w,
  chunk_reload_client_OwfuJ5kbYW,
  umami_UbpOuIUCD6,
  _2_pouchdb_client_rNyFaRK3ZK,
  axios_QMFgzss1s4,
  sentry_client_shVUlIjFLk
]