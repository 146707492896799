import { default as indexYgNQUVbTiDMeta } from "/usr/src/app/pages/[servicio]/index.vue?macro=true";
import { default as mapacEgaj24zR7Meta } from "/usr/src/app/pages/[servicio]/mapa.vue?macro=true";
import { default as saldoOmfAEMaoHCMeta } from "/usr/src/app/pages/[servicio]/saldo.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indexss5GFqUOHRMeta } from "/usr/src/app/pages/[servicio]/index?macro=true";
import { default as mapazcJrOwJSzXMeta } from "/usr/src/app/pages/[servicio]/mapa?macro=true";
import { default as saldoZgn7lom21gMeta } from "/usr/src/app/pages/[servicio]/saldo?macro=true";
export default [
  {
    name: indexYgNQUVbTiDMeta?.name ?? "servicio",
    path: indexYgNQUVbTiDMeta?.path ?? "/:servicio()",
    meta: indexYgNQUVbTiDMeta || {},
    alias: indexYgNQUVbTiDMeta?.alias || [],
    redirect: indexYgNQUVbTiDMeta?.redirect,
    component: () => import("/usr/src/app/pages/[servicio]/index.vue").then(m => m.default || m)
  },
  {
    name: mapacEgaj24zR7Meta?.name ?? "servicio-mapa",
    path: mapacEgaj24zR7Meta?.path ?? "/:servicio()/mapa",
    meta: mapacEgaj24zR7Meta || {},
    alias: mapacEgaj24zR7Meta?.alias || [],
    redirect: mapacEgaj24zR7Meta?.redirect,
    component: () => import("/usr/src/app/pages/[servicio]/mapa.vue").then(m => m.default || m)
  },
  {
    name: saldoOmfAEMaoHCMeta?.name ?? "servicio-saldo",
    path: saldoOmfAEMaoHCMeta?.path ?? "/:servicio()/saldo",
    meta: saldoOmfAEMaoHCMeta || {},
    alias: saldoOmfAEMaoHCMeta?.alias || [],
    redirect: saldoOmfAEMaoHCMeta?.redirect,
    component: () => import("/usr/src/app/pages/[servicio]/saldo.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexss5GFqUOHRMeta?.name ?? undefined,
    path: indexss5GFqUOHRMeta?.path ?? "/:servicio(biotren|laja-talcahuano|victoria-temuco)/:idsalida?/:idllegada?",
    meta: indexss5GFqUOHRMeta || {},
    alias: indexss5GFqUOHRMeta?.alias || [],
    redirect: indexss5GFqUOHRMeta?.redirect,
    component: () => import("/usr/src/app/pages/[servicio]/index").then(m => m.default || m)
  },
  {
    name: mapazcJrOwJSzXMeta?.name ?? undefined,
    path: mapazcJrOwJSzXMeta?.path ?? "/:servicio(biotren|laja-talcahuano|victoria-temuco)/mapa",
    meta: mapazcJrOwJSzXMeta || {},
    alias: mapazcJrOwJSzXMeta?.alias || [],
    redirect: mapazcJrOwJSzXMeta?.redirect,
    component: () => import("/usr/src/app/pages/[servicio]/mapa").then(m => m.default || m)
  },
  {
    name: saldoZgn7lom21gMeta?.name ?? undefined,
    path: saldoZgn7lom21gMeta?.path ?? "/:servicio(biotren|laja-talcahuano|victoria-temuco)/saldo",
    meta: saldoZgn7lom21gMeta || {},
    alias: saldoZgn7lom21gMeta?.alias || [],
    redirect: saldoZgn7lom21gMeta?.redirect,
    component: () => import("/usr/src/app/pages/[servicio]/saldo").then(m => m.default || m)
  }
]